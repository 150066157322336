import {
    Container,
    Stack,
    Flex,
    Box,
    Heading,
    Text,
    Button,
    Image,
  } from '@chakra-ui/react';

  import bgHero from '../assets/bg-hero.svg'
  import phonesHero from '../assets/phones.svg'
  
  export default function Hero() {
    return (
      <Container maxW={'1440px'} backgroundImage={bgHero} overflow={'hidden'} borderBottomRadius={20}>
        <Stack
          align={'center'}
          spacing={{ base: 8, md: 10 }}
          p={{ base: 10, md: 28 }}
          direction={{ base: 'column', md: 'row' }}>
          <Stack flex={1} spacing={{ base: 5, md: 10 }}>
            <Heading
              lineHeight={1.1}
              fontWeight={600}
              fontSize={{ base: '2xl', sm: '3xl', lg: '5xl' }}>
              <Text as={'span'} color={'white'}>
              Comece seu negócio com o MVP ideal
              </Text>
            </Heading>
            <Text color={'gray.100'}>
            Transforme sua ideia em um produto de sucesso com o mínimo de esforço
            </Text>
            <Stack
              spacing={{ base: 4, sm: 6 }}
              direction={{ base: 'column', sm: 'row' }}>
              <Button
                rounded={'full'}
                size={'lg'}
                fontWeight={'normal'}
                px={6}
                colorScheme={'orange'}
                bg={'orange.400'}
                _hover={{ bg: 'orange.500' }}>
                Fale conosco
              </Button>
            </Stack>
          </Stack>
          <Flex
            flex={1}
            justify={'center'}
            align={'center'}
            position={'relative'}
            w={'full'}>
            <Box
            justify={'center'}
            align={'center'}
              top={{ base: 150, md: -220 }}
              right={{ base: 0, md: -100 }}
              position={{ base: 'relative', md: 'absolute' }}
              overflow={'hidden'}
              >
              <Image
                justify={"center"}
                alt={'Hero Image'}
                fit={'cover'}
                align={'center'}
                w={{base:'100%', md:'75%'}}
                h={'auto'}
                src={
                  phonesHero
                }
              />
            </Box>
          </Flex>
        </Stack>
      </Container>
    );
  }
