import {
    Container,
    Stack,
    Flex,
    Box,
    Heading,
    Text,
    Button,
    Image
  } from '@chakra-ui/react';

  import circleBg from '../assets/circle.svg';
  
import rocket from '../assets/rocket.svg'

  export default function Explanation() {
    return (
      <Container maxW={'7xl'} id='rocket'>
        <Stack
          align={'center'}
          spacing={{ base: 8, md: 10 }}
          py={{ base: 10, md: 10 }}
          direction={{ base: 'column', md: 'row' }}>
          <Stack flex={1} spacing={{ base: 5, md: 10 }}>
         
            <Heading
              lineHeight={1.1}
              fontWeight={600}
              fontSize={{ base: '2xl', sm: '3xl', lg: '4xl' }}>
                <div data-aos="fade-up-right">
              <Text as={'span'} color={'blue.800'}>
              Crie seu MVP de maneira rápida e fácil
              </Text>
              </div>
            </Heading>
            <div data-aos="fade-up-right">
            <Text color={'gray.500'}>
            Com nossa ajuda, você pode construir seu MVP em poucos dias e sem investir muito dinheiro.
            </Text>
            </div>
            <Stack
              spacing={{ base: 4, sm: 6 }}
              justify={{base: 'center', md: 'start'}}
              align={{base: 'center', md: 'start'}}
              direction={{ base: 'column', sm: 'row' }}>
                <div data-aos="fade-up-right">
              <Button
                rounded={'full'}
                size={'lg'}
                fontWeight={'normal'}
                px={6}
                colorScheme={'orange'}
                bg={'orange.400'}
                _hover={{ bg: 'orange.500' }}>
                Fale conosco
              </Button>
              </div>
            </Stack>
          </Stack>
          <Flex
            flex={1}
            justify={'center'}
            align={'center'}
            position={'relative'}
            w={'100%'}>
           <div data-aos="fade-down-right">
            <Box
              position={'relative'}
              justify={'center'}
              align={'center'}
              width={'100%'}
              backgroundImage={circleBg}
              backgroundRepeat={'no-repeat'}
              objectFit={'cover'}
           >
           
            <div data-aos="fade-up"
              data-aos-anchor-placement="center-bottom"
              className='rocket'
              >
              <Image
              
                alt={'Hero Image'}
                fit={'cover'}
                justify={'center'}
                align={'center'}
                w={{base: '75%', md: '100%'}}
                h={{base: '75%', md: '100%'}}
                src={
                  rocket
                }
              />
              </div>
            </Box>
            </div>
          </Flex>
        </Stack>
      </Container>
    );
  }
  
