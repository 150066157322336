import {
    Container,
    Stack,
    Flex,
    Box,
    Heading,
    Text,
    Button,
    Image
  } from '@chakra-ui/react';
  
import Team from '../assets/team.svg'
import fullTeam from '../assets/fullTeam.png'

  export default function Explanation() {
    return (
      <Container maxW={'7xl'} id='team'>
        <Stack
          align={'center'}
          spacing={{ base: 8, md: 10 }}
          py={{ base: 10, md: 10 }}
          direction={{ base: 'column', md: 'row' }}>
          <Stack flex={1} spacing={{ base: 5, md: 10 }}>
            
            <Heading
              lineHeight={1.1}
              fontWeight={600}
              fontSize={{ base: '2xl', sm: '3xl', lg: '4xl' }}>
                <div data-aos="fade-up-right">
              <Text as={'span'} color={'blue.800'}>
              Benefícios de um MVP bem-sucedido
              </Text>
              </div>
            </Heading>
            <div data-aos="fade-up-right">
            <Text color={'gray.500'}>
            Comece pequeno e cresça rápido com o MVP certo.
            </Text>
            </div>
            <Stack
              spacing={{ base: 4, sm: 6 }}
              justify={{base: 'center', md: 'start'}}
              align={{base: 'center', md: 'start'}}
              direction={{ base: 'column', sm: 'row' }}>
                 <div data-aos="fade-up-right">
              <Button
                rounded={'full'}
                size={'lg'}
                fontWeight={'normal'}
                px={6}
                colorScheme={'orange'}
                bg={'orange.400'}
                _hover={{ bg: 'orange.500' }}>
                Fale conosco
              </Button>
              </div>
            </Stack>
          </Stack>
          <Flex
            flex={1}
            justify={'center'}
            align={'center'}
            position={'relative'}
            w={'100%'}>
           
            <Box
              position={'relative'}
              justify={'center'}
              align={'center'}
              width={'100%'}
           > 
             <Image
             className='team'
                alt={'Hero Image'}
                fit={'cover'}
                position={'absolute'}
                zIndex={10}
                justify={'center'}
                align={'center'}
                w={{ md: '100%'}}
                h={{ md: '100%'}}
                src={
                  fullTeam
                }
              />
              <Image
              data-aos="fade-up-left"
              className={{base:'team', md:'rotate-center'}}
                alt={'Hero Image'}
                fit={'cover'}
                justify={'center'}
                align={'center'}
                w={{ md: '100%'}}
                h={{ md: '100%'}}
                src={
                  Team
                }
              />
            </Box>
          </Flex>
        </Stack>
      </Container>
    );
  }
  
